// app/javascript/images.js
// 初始化所有功能
const initializeImageFeatures = () => {
  // 清理舊的 clipboard 實例
  if (clipboardInstance) {
    clipboardInstance.destroy();
  }

  // 初始化 ClipboardJS
  clipboardInstance = new ClipboardJS('.copy-btn', {
    text: function(trigger) {
      return trigger.getAttribute('data-clipboard-text');
    }
  });

  // 使用事件委派處理保存按鈕點擊
  document.addEventListener('click', (e) => {
    if (e.target.closest('.save-name-btn')) {
      const btn = e.target.closest('.save-name-btn');
      const imageId = btn.dataset.imageId;
      const input = document.querySelector(`input[data-image-id="${imageId}"]`);
      const newName = input.value;
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
      const folderId = document.querySelector('.panel-container').dataset.folderId;

      fetch(`/admin/file_folders/${folderId}/images/${imageId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
          'Accept': 'application/json'
        },
        body: JSON.stringify({ image: { name: newName } })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          // 更新按鈕狀態
          const originalHTML = btn.innerHTML;
          btn.innerHTML = '<i class="fal fa-check"></i>';
          btn.classList.remove('btn-primary');
          btn.classList.add('btn-success');
          
          setTimeout(() => {
            btn.innerHTML = originalHTML;
            btn.classList.remove('btn-success');
            btn.classList.add('btn-primary');
          }, 1000);

          // 刷新頁面以顯示 flash 消息
          window.location.reload();
        }
      })
      .catch(error => {
        console.error('Error:', error);
        window.location.reload(); // 失敗時也重新載入頁面以顯示錯誤消息
      });
    }
  });
};

// 監聽 Turbo 事件
document.addEventListener('turbo:load', initializeImageFeatures);
document.addEventListener('turbo:render', initializeImageFeatures);