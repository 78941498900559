// app/javascript/packs/application.js

// 先引入 rails-ujs
import Rails from "@rails/ujs"

// 檢查是否已經啟動，如果沒有才啟動
if (typeof Rails !== 'undefined' && !Rails.started) {
  Rails.start()
}

// 使用 import 而不是 require，這樣可以避免一些載入順序的問題
import "@rails/activestorage"
import "channels"
import "./images"  // 引入我們的 images.js

// 啟動 activestorage
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()